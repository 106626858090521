<!--
 * @Author: lsw lsw_0524@163.com
 * @Date: 2024-01-11 19:57:18
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2024-01-26 11:05:34
 * @FilePath: \yiyun_project\src\views\extension\distribution\accelerateList.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <el-main>
    <el-form class="el-form-search" label-width="100px">
      <el-form-item label="手机号：">
        <el-input size="small" v-model="formData.mobile"></el-input>
      </el-form-item>
      <el-form-item label="用户名称:">
        <el-input size="small" v-model="formData.distribution_name"></el-input>
      </el-form-item>
      <el-form-item label="可返款日期：">
        <el-date-picker v-model="formData.create_time" size="small" type="daterange" range-separator="~" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
      </el-form-item>
      <el-form-item label=" " label-width="25px">
        <el-button size="small" type="primary" @click="getDataList(1)">搜索</el-button>
        <el-button size="small" type="text" @click="clearSearch">清除搜索条件</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="dataInfo.list" :header-cell-style="{ 'background-color': '#F8F9FA' }">
      <template slot="empty">
        <No />
      </template>
      <el-table-column prop="id" label="ID" align="center"></el-table-column>
      <el-table-column prop="distribution_info.nickname" label="用户名称" align="center"></el-table-column>
      <el-table-column prop="distribution_info.mobile" label="手机号" align="center"></el-table-column>
      <el-table-column prop="money" label="需返款金额" align="center"></el-table-column>
      <el-table-column prop="back_money" label="已返款金额" align="center"></el-table-column>
      <el-table-column label="可返款日期" align="center">
        <template v-slot="{ row }">{{ getDateformat(row.need_back_time) }}</template>
      </el-table-column>
      <el-table-column label="创建日期" align="center">
        <template v-slot="{ row }">{{ getDateformat(row.create_time) }}</template>
      </el-table-column>
      <el-table-column prop="remark" label="备注" align="center"></el-table-column>
      <el-table-column label="操作" align="center">
        <template v-slot="{ row }">
          <el-button v-if="Number(row.back_money) == 0" type="text" @click="payDialogOpen(row)">打款</el-button>
          <el-button type="text" v-else>已打款</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Paging :total="dataInfo.total_number" :page="dataInfo.page" :pageNum="dataInfo.rows" @updatePageNum="updateData"></Paging>
    <!-- 打款弹窗 -->
    <el-dialog title="打款" :visible.sync="payment_dialog">
      <el-form label-width="100px">
        <el-form-item label="收款人">
          {{ pay_data_info.distribution_info.nickname }}
        </el-form-item>
        <el-form-item label="手机号">
          {{ pay_data_info.distribution_info.mobile }}
        </el-form-item>
        <el-form-item label="需打款金额">
          {{ pay_data_info.money }}
        </el-form-item>
        <el-form-item label="已打款金额">
          {{ pay_data_info.back_money }}
        </el-form-item>
        <el-form-item label="打款金额">
          <el-input v-model="pay_money"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="payment_dialog = false">取 消</el-button>
        <el-button type="primary" @click="surePayMoney">确 定</el-button>
      </div>
    </el-dialog>
  </el-main>
</template>

<script>
import Paging from '@/components/paging';
import { getDateformat } from '@/util/getDate';
export default {
  components: {
    Paging,
  },
  data() {
    return {
      getDateformat,
      formData: {
        mobile: '',
        distribution_name: '',
        create_time: [],
      },
      dataInfo: {
        page: 1,
        rows: 10,
        list: [],
        total_number: 0,
      },
      payment_dialog: false,
      // 被打款的人物数据
      pay_data_info: {
        distribution_info: {},
      },
      pay_money: null,
    };
  },
  created() {
    this.getDataList();
  },
  methods: {
    // 确认打款
    surePayMoney() {
      if (!parseFloat(this.pay_money)) {
        this.$message.warning('请输入打款金额');
        return;
      }
      if (parseFloat(this.pay_money) > parseFloat(this.pay_data_info.money)) {
        this.$message.warning('打款金额不可大于需打款金额');
        return;
      }
      this.$axios
        .post(this.$api.storageList.payCash, {
          id: this.pay_data_info.id,
          back_money: this.pay_money,
        })
        .then(res => {
          if (res.code == 0) {
            this.$message.success('操作成功');
            this.getDataList();
            this.payment_dialog = false;
          } else {
            this.$message.warning(res.msg);
          }
        });
    },
    payDialogOpen(data) {
      let now_time = Math.floor(new Date().getTime() / 1000);
      if (now_time <= data.need_back_time) {
        this.$alert('该笔款项暂未到达打款时间', '提示', {
          confirmButtonText: '确定',
          type: 'warning',
        });
        return;
      }
      this.pay_money = null;
      this.pay_data_info = data;
      this.payment_dialog = true;
    },
    clearSearch() {
      this.formData = {
        mobile: '',
        distribution_name: '',
        create_time: [],
      };
      this.getDataList(1);
    },
    updateData(val, status) {
      if (status == 0) {
        this.dataInfo.rows = val;
      } else {
        this.dataInfo.page = val;
      }
      this.getDataList();
    },
    getDataList(style) {
      if (style == 1) {
        this.dataInfo.page = 1;
      }
      let obj = {
        page: this.dataInfo.page,
        rows: this.dataInfo.rows,
      };
      //  distribution_name  mobile  need_back_time
      if (this.formData.mobile) obj.mobile = this.formData.mobile;
      if (this.formData.distribution_name) obj.distribution_name = this.formData.distribution_name;
      if (this.formData.create_time?.length) {
        obj.need_back_time = [this.formData.create_time[0].getTime() / 1000, this.formData.create_time[1].getTime() / 1000];
        if (obj.need_back_time[0] == obj.need_back_time[1]) obj.need_back_time[1] = obj.need_back_time[0] + 60 * 60 * 24 - 1;
      }
      this.$axios.post(this.$api.storageList.DistributionMemberCashLog, obj).then(res => {
        if (res.code == 0) {
          this.dataInfo.list = res.result.list;
          this.dataInfo.total_number = res.result.total_number;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
}
</style>
